.lagesize
{
    
    font-size: 300%;
      
}
.x150size
{
    
    font-size: 120%;
      
}